export default {
	methods:
		{
			/**
			 * retuen word ending
			 **/
			returnWord: function (points)
			{
				var cartLength = points % 100,
					mod = cartLength % 10;

				if (cartLength != 11 && mod == 1)
					return '';
				else if (mod >= 2 && mod <= 4 && (cartLength < 10 || cartLength > 20))
					return 'а';
				else
					return 'ов';
			},
		}
}