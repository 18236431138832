<template>
	<div class="cart-meal-row">
		<div class="cart-meal-row__descr">
			<div
				class="cart-meal-row__img"
				:style="{ backgroundColor: getCategoryColor(item?.type?.code)}"
			>
				<img
					:src="meal.product.preview_picture.thumb"
					alt="Meal image"
				>
			</div>
			<div class="cart-meals-row__descr-wr">
				<h2 class="cart-meal-row__name">{{ item.name }}</h2>
				<span class="cart-meal-row__meal-type">
					{{ item.type.name }} / {{ item.weight || 0 }} гр / {{ item.calories || 0 }} Ккал
				</span>
			</div>
		</div>
		<div class="cart-meal-row__right">
			<div class="cart-meal-row__cost">
				<Counter
					:value="+count"
					:canChange="!isGift"
					:checkCounterBetweenChange="checkCartBeforeAddProduct"
					@change="changeCartItem($event, meal?.cart_id)"
				/>
				<div class="cart-meal-row__price-for-one">
					{{ isGift ? 0 : item?.price }} ₽/шт
				</div>
			</div>
			<div class="cart-meal-row__total">
				<span class="cart-meal-row__total_value">
					{{ isGift ? 0 : addThousandSeparator(item.price * meal.count) }} ₽
				</span>
				<div
					class="cart-meal-row__total_mark"
					v-if="item.balls && currentBasket?.owner && !isGift"
				>
					+{{ item.balls * meal.count }}
					<img
						src="~@/assets/img/icons/bonus.svg"
						alt="Bonus"
					/>
				</div>
				<div
					class="cart-meal-row__gift"
					v-if="isGift"
				>
					<svg
						fill="transparent"
						width="40"
						height="40"
					>
						<use xlink:href="#cart-row__gift"></use>
					</svg>
				</div>
			</div>
			<img
				@click="!isGift && changeCartItem(0, meal?.cart_id)"
				src="@/assets/img/close.svg"
				class="cart-meal-row__remove"
				alt="Remove"
			/>
		</div>
	</div>
</template>

<script>
import WordEnding from '@/mixins/returnWordEnding.js'
import {useChangeCartItem} from "@/composables";
import {mapGetters} from "vuex";
import {addThousandSeparator} from "@/helpers";

export default {
	setup()
	{
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count} = useChangeCartItem();

		return {checkCartBeforeAddProduct, changeCartItem, initProduct, count};
	},
	components: {Counter: () => import("@/components/ui/Counter.vue"),},
	computed: {
		...mapGetters({currentBasket: "getCurrentBasket"}),
	},
	props:
		{
			meal: Object,
			item: Object,
			productCount: Number,
			isGift: {
				type: Boolean,
				default: false
			}
		},
	mixins: [WordEnding],
	methods:
		{
			addThousandSeparator,
			/**
			 ** меняет задний фон в зависимости от категории
			 **/
			getCategoryColor(category)
			{
				if (!category || !this.$store.state.menuCategories) return;

				const res = this.$store.state.menuCategories.find(c => c.type == category);

				if (!res || !res.color) return;

				return `#${res.color}`;
			},
		},
	watch:
		{
			productCount()
			{
				if (this.productCount) this.initProduct({...this.item, count: this.productCount});
			}
		},
	mounted()
	{
		this.initProduct({...this.item, count: this.productCount});
	}
}
</script>

<style lang="scss">
.cart-meal-row
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-meal-row__descr
{
	display: flex;
	align-items: flex-start;
	gap: 24px;
}

.cart-meal-row__img
{
	width: 96px;
	height: 96px;
	border-radius: 20px;

	img
	{
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.cart-meals-row__descr-wr
{
	display: flex;
	flex-direction: column;
	gap: 8px;

	margin-top: 10px;
}

.cart-meal-row__name
{
	font-family: $mainFontBoldNew;
	font-size: 18px;
	line-height: 24px;
}

.cart-meal-row__meal-type
{
	font-family: $mainFontNew;
	font-size: 12px;
	line-height: 16px;
	color: $greyLightText;
}

.cart-meal-row__right
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	max-width: 370px;
	flex: 1;

	margin-top: 10px;
}

.cart-meal-row__cost
{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.cart-meal-row__price-for-one
{
	background-color: $greyBackground;
	border-radius: 2px;
	padding: 4px 6px;
	font-size: 14px;
	line-height: 20px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.cart-meal-row__total
{
	display: flex;
	align-items: center;
	gap: 10px;
}

.cart-meal-row__total_value
{
	white-space: nowrap;
	font-family: $mainFontBoldNew;
	font-size: 24px;
	line-height: 36px;
	color: $textColor;
}

.cart-meal-row__total_mark
{
	display: flex;
	align-items: center;
	justify-content: center;
	background: $green;
	border-radius: 4px;
	padding: 2px 4px;

	font-family: $mainFontBoldNew;
	font-size: 14px;
	line-height: 18px;
	color: white;
}

.cart-meal-row__remove
{
	margin-top: 6px;
	cursor: pointer;
}
</style>
